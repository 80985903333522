import React from 'react'
import ContactMap from '../Contact/ContactMap/ContactMap'
import ContactCal from '../Contact/ContactCal/ContactCal'


const Contact = () => {
  return (
    <div>
        <ContactMap/>
        <ContactCal/>
    </div>
  )
}

export default Contact