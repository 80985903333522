import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './maincalendar.css';

const Maincalendar = ({ onDateSelection, clearError}) => {
  const [currMonth, setCurrMonth] = useState(new Date().getMonth());
  const [currYear, setCurrYear] = useState(new Date().getFullYear());
  const [selectedDate, setSelectedDate] = useState(null);
  const [calendarError, setCalendarError] = useState(false); 

  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const today = new Date();

  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (year, month) => {
    return new Date(year, month, 1).getDay();
  };

  const generateCalendar = () => {
    const daysInMonth = getDaysInMonth(currYear, currMonth);
    const firstDayOfMonth = getFirstDayOfMonth(currYear, currMonth);

    const calendarDays = Array.from({ length: 42 }, (_, i) => {
      const dayOfMonth = i - firstDayOfMonth + 1;
      const date = new Date(currYear, currMonth, dayOfMonth);
      const isSelectable = 
        dayOfMonth > 0 && 
        dayOfMonth <= daysInMonth &&
        (date >= today || date.toDateString() === today.toDateString());
      return dayOfMonth > 0 && dayOfMonth <= daysInMonth ? { day: dayOfMonth, selectable: isSelectable } : null;
    });

    return (
      <div className="calendar-days">
        {calendarDays.map((dayObj, index) => (
          <div
            key={index}
            className={`calendar-day${
              dayObj && dayObj.day === today.getDate() && 
              currYear === today.getFullYear() &&
              currMonth === today.getMonth()  
                ? ' curr-date' : ''}${dayObj && dayObj.day === selectedDate ? ' selected' : ''}${dayObj && !dayObj.selectable ? ' non-selectable' : ''}`}
            onClick={() => dayObj && dayObj.selectable && handleDayClick(dayObj.day)}
          >
            {dayObj !== null ? dayObj.day : ''}
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        ))}
      </div>
    );
  };

  const handleDayClick = (day) => {
    setSelectedDate(day);
    setCalendarError(false); // Clear calendar error when date is selected
    const selectedDateObj = new Date(currYear, currMonth, day);
    const formattedDate = `${selectedDateObj.getDate()}/${selectedDateObj.getMonth() + 1}/${selectedDateObj.getFullYear()}`;
    console.log('Selected Date:', formattedDate);
    onDateSelection(formattedDate);
    clearError(); // Clear errors when date is selected
  };

  const handleMonthChange = (event) => {
    const selectedMonth = event.target.value;
    setCurrMonth(parseInt(selectedMonth, 10));
    if (selectedDate > getDaysInMonth(currYear, selectedMonth)) {
      setSelectedDate(null);
    }
    clearError(); // Clear errors on month change
  };

  const handleYearChange = (increment) => {
    setCurrYear((prevYear) => prevYear + increment);
    setSelectedDate(null);
    clearError(); // Clear errors on year change
  };

  return (
    <div className='calendarmain'>
      <div className="calendar-header">
        <div className="year-picker">
          <span className="year-change" onClick={() => handleYearChange(-1)}>
            <pre id='one'>{'<'}</pre>
          </span>

          <select className="month-picker" value={currMonth} onChange={handleMonthChange}>
            {monthNames.map((month, index) => (
              <option key={index} value={index}>
                {month}
              </option>
            ))}
          </select>

          <span>{currYear}</span>
          <span className="year-change" onClick={() => handleYearChange(1)}>
            <pre id='two'>{'>'}</pre>
          </span>
        </div>
      </div>
      <hr className='whiteline' />
      <div className="calendar-body">
        <div className="calendar-week-day">
          {weekDays.map((day, index) => (
            <div key={index}>{day}</div>
          ))}
        </div>
        {generateCalendar()}
      </div>

      {calendarError && <div className="error-message">Please select a date in the calendar.</div>}

      <input type="hidden" id="selectedDate" value={selectedDate || ''} />
    </div>
  );
};

Maincalendar.propTypes = {
  onDateSelection: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
};

export default Maincalendar;
