import React from 'react';
import { useNavigate } from 'react-router-dom';
import "../Services/services.css";
import Headimg from "./assets/FSDHEAD.jpeg";
import fd1 from './assets/fd1.png';
import fd2 from './assets/fd2.png';
import fd3 from './assets/fd3.png';
import fd4 from './assets/fd4.png';
import fd5 from './assets/fd5.png';
import fd6 from './assets/fd6.png';
import fd7 from './assets/fd7.png';
import fd8 from './assets/fd8.png';

function Service2() {
  const navigate = useNavigate();

  const handleBookSlotClick = () => {
    navigate('/contact');
  };

  return (
    <div>
      <div className='heading'>
        <div className='headtext'>
          <h1>Full-Stack Development</h1>
          <p>Transform your ideas into reality with our comprehensive Full Stack Development services, ensuring seamless, end-to-end solutions.</p>
          <button className='btn' onClick={handleBookSlotClick}>Book your Slot</button>
        </div>
        <div className='blogimg1'>
          <img src={Headimg} alt="Full Stack Development" />
        </div>
      </div>

      <div className='process'>
        <h1>Our Working process</h1>
        <div className='steps'>
          <div className='oddstep'>
            <div className='oddstep-text'>
              <p>
                <ul>
                  <li>Study market trends and user needs.</li>
                  <li>Explore latest technologies and frameworks.</li>
                </ul>
              </p>
            </div>
            <div className='count1'>
              <h1>01</h1>
              <h6>Research</h6>
            </div>
          </div>

          <div className='evenstep'>
            <div className='count2'>
              <h1>02</h1>
              <h6>Analyse</h6>
            </div>
            <div className='evenstep-text'>
              <p>
                <ul>
                  <li>Evaluate project requirements and feasibility.</li>
                  <li>Assess potential challenges and solutions.</li>
                </ul>
              </p>
            </div>
          </div>
           
          <div className='oddstep'>
            <div className='oddstep-text'>
              <p>
                <ul>
                  <li>Create intuitive and user-friendly interfaces.</li>
                  <li>Develop scalable and robust system architectures.</li>
                </ul>
              </p>
            </div>
            <div className='count3'>
              <h1>03</h1>
              <h6>Design</h6>
            </div>
          </div>

          <div className='evenstep'>
            <div className='count4'>
              <h1>04</h1>
              <h6>Ideate</h6>
            </div>
            <div className='evenstep-text'>
              <p>
                <ul>
                  <li>Brainstorm innovative features and functionalities.</li>
                  <li>Collaborate to refine concepts and enhance user experience.</li>
                </ul>
              </p>
            </div>
          </div>

          <div className='oddstep'>
            <div className='oddstep-text'>
              <p>
                <ul>
                  <li>Develop initial versions of the product for testing.</li>
                  <li>Gather feedback to iterate and improve the design.</li>
                </ul>
              </p>
            </div>
            <div className='count5'>
              <h1>05</h1>
              <h6>Prototype</h6>
            </div>
          </div>
        </div>
      </div>

      <div className='toolhead'>
        <h1>Tools we use</h1>
        <div className='tools'>
          <div className='icon'>
            <img src={fd1} alt="Tool 1" />
            <img src={fd2} alt="Tool 2" />
            <img src={fd3} alt="Tool 3" />
            <img src={fd7} alt="Tool 7" />
          </div>
          <div className='icon'>
            <img src={fd4} alt="Tool 4" />
            <img src={fd5} alt="Tool 5" />
            <img src={fd6} alt="Tool 6" />
            <img src={fd8} alt="Tool 8" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Service2;
