import { useLayoutEffect, useRef,useEffect,useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link as ScrollLink } from 'react-scroll';
import "./styles.css";

gsap.registerPlugin(ScrollTrigger);

function Example() {

  //Checking active sections
  const [activeSection, setActiveSection] = useState(null);
  const sectionRefs = useRef([]);
  const menuRef = useRef(null);

  // console.log(activeSection);
 

  useEffect(() => {
    const sections = document.querySelectorAll(".desktopContentSection");

    sectionRefs.current = Array.from(sections).map((section) => ({
      section,
      id: section.id,
      link: document.querySelector(`[to="${section.id}"]`)
    }));

    
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5 // Trigger when 50% of the section is visible
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    sectionRefs.current.forEach(({ section }) => {
      observer.observe(section);
    });

    return () => {
      observer.disconnect();
    };
  }, []);


  
  


  //offset for scrollLink
  const calculateOffset = () => {
    const containerHeight = window.innerHeight * 0.7; // 70vh
    const viewportHeight = window.innerHeight;
    const offset = viewportHeight / 1.7 - containerHeight;
    return offset;
  };



  const comp = useRef(null);
  const galleryRef = useRef(null);

  // const serviceBoxRef = useRef(null);
  // const lastChildRef = useRef(null);  

  // const debounce = (func, wait) => {
  //   let timeout;
  //   return (...args) => {
  //     clearTimeout(timeout);
  //     timeout = setTimeout(() => func(...args), wait);
  //   };
  // };

  // useEffect(() => {
    // const serviceBox = serviceBoxRef.current;
    // const lastChild = lastChildRef.current;

    // if (!serviceBox || !lastChild) return;

    // const handleScroll = () => {
      // const serviceBoxTop = serviceBox.getBoundingClientRect().top;
      // const lastChildTop = lastChild.getBoundingClientRect().top;

      // if (serviceBoxTop <= 184 && lastChildTop >= 600 && lastChildTop <= 2950) {
      //   serviceBox.classList.add('fixed');
      
      // } else {
      //   serviceBox.classList.remove('fixed');
      
      // }
    // };

    // const observerOptions = {
    //   root: null,
    //   rootMargin: '0px',
    //   threshold: 0
    // };

    // const observerCallback = () => {};

    // const observer = new IntersectionObserver(observerCallback, observerOptions);
    // observer.observe(lastChild);

    // const debouncedHandleScroll = debounce(handleScroll, 50);
    // window.addEventListener('scroll', debouncedHandleScroll);
    // window.addEventListener('scroll', handleScroll);

    // return () => {
      // observer.disconnect();
      // window.removeEventListener('scroll', handleScroll);
      // window.removeEventListener('scroll', debouncedHandleScroll);
    // };
  // }, []);

  useEffect(() => {
    const handleScroll = () => {
      const menus = menuRef.current;
      const fristDiv = comp.current;
      const secondDiv = galleryRef.current;
      const rectOne = fristDiv.getBoundingClientRect();
      const rect = secondDiv.getBoundingClientRect();

      if (rectOne.top >= -110 && rect.bottom >= 750 ) {
        menus.classList.remove('fixed-button');
        menus.classList.remove('hide');
      } else {
        if (rect.top <= 240 && rect.bottom >= 750) {
          menus.classList.add('fixed-button');
          menus.classList.remove('hide');
        } else {
          // button.classList.remove('fixed-button');
          menus.classList.add('hide');
        }
      }
    };

    const debounce = (func, wait) => {
      let timeout;
      return function(...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
      };
    };

    const debouncedHandleScroll = debounce(handleScroll, 10);

    window.addEventListener('scroll', debouncedHandleScroll);

    return () => {
      window.removeEventListener('scroll', debouncedHandleScroll);
    };
  }, []);


  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
  
      const photos = gsap.utils.toArray(".desktopPhoto");
      const details = gsap.utils.toArray(".desktopContentSection");

      gsap.set(photos, { autoAlpha: 0 }); // Initially hide all photos
      gsap.set(details, { autoAlpha: 0 }); // Initially hide all details


      let mm = gsap.matchMedia();
      // create

      // add a media query. When it matches, the associated function will run
      mm.add("(min-width: 834px)", () => {
        // this setup code only runs when viewport is at least 600px wide
        // console.log("desktop");

        ScrollTrigger.create({
          trigger: galleryRef.current,
          start: "top top",
          end: "bottom bottom",
          pin: ".right"
        });

       

        details.forEach((detail, index) => {
          let headline = detail.querySelector("h1");
          let animationPhotos = gsap.timeline()
          .to(photos[index], { autoAlpha: 1, duration: 0.5 }, 1);
  
        if (index !== photos.length - 1) {
          animationPhotos.to(photos[index], { autoAlpha: 0, duration: 1 }, 2);
        } else {
          // Fade in last detail when the second last photo starts fading out
          animationPhotos.add(() => gsap.to(detail, { autoAlpha: 1, duration: 0.5 }), 3.5);
        }
  
        let animationDetails = gsap.timeline()
          .fromTo(detail, { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.5 }, 0);
  
        if (index !== details.length - 1) {
          animationDetails.to(detail, { autoAlpha: 0, duration: 0.5 }, 9);
        }
  
     

          ScrollTrigger.create({
            trigger: detail,
            start: "top 100%",
            end: "top 0%",
            animation: animationDetails,
            scrub: true,
            markers: false
          });

          ScrollTrigger.create({
            trigger: headline,
            start: "top 100%",
            end: "top 0%",
            animation: animationPhotos,
            scrub: true,
            markers: false
          });
          


        });

        return () => {
          // optional
          // custom cleanup code here (runs when it STOPS matching)
          // console.log("mobile");
        };
      });
    }, comp);

    return () => {
      ctx.revert();
    };
  }, []);



  return (
    <div ref={comp}>
      {/* <div className="spacer"> */}

      <div className="home-service-container">
        <div className="home-services">
        <div className="home-mfai-subtitle"><button>Services</button></div>
        <h1>What we're good at</h1>
        <div className="service-box" ref={menuRef}>
          <div className={`service-link ${activeSection === "ML" ? "active" : ""}`}><ScrollLink to="ML" smooth={true} duration={1350} offset={calculateOffset()}>Machine Learning</ScrollLink></div>
          <div className={`service-link ${activeSection === "GENAI" ? "active" : ""}`}><ScrollLink to="GENAI" smooth={true} duration={1350} offset={calculateOffset()}>GEN AI</ScrollLink></div>
          <div className={`service-link ${activeSection === "UIUX" ? "active" : ""}`}><ScrollLink to="UIUX" smooth={true} duration={1350} offset={calculateOffset()}>UI/UX</ScrollLink></div>
          <div className={`service-link ${activeSection === "MERN" ? "active" : ""}`}><ScrollLink to="MERN" smooth={true} duration={1350} offset={calculateOffset()}>Mern Stack</ScrollLink></div>
          <div className={`service-link ${activeSection === "CLOUD" ? "active" : ""}`}><ScrollLink to="CLOUD" smooth={true} duration={1350} offset={calculateOffset()}>Cloud Services</ScrollLink></div>
          
          {/* Dummy Class */}
          <div className={`service-link temp ${activeSection === "Temp" ? "active" : ""}`}></div> 
        </div>
        </div>
      </div>

      <div ref={galleryRef} className="gallery">
      
        <div className="left">
          <div className="desktopContent">
            <div className="desktopContentSection ML" id="ML">
              <h1>Machine Learning</h1>
              <p>
              At MindFulAI, we offer advanced machine learning services to help your business thrive. Our solutions are designed to provide valuable insights and automate tasks, making your operations smarter and more efficient.
              </p>
            </div>
            <div className="desktopContentSection GENAI" id="GENAI">
              <h1>Gen AI</h1>
              <p>
              At MindFulAI, we're revolutionizing the way businesses operate with the power of Artificial Intelligence (AI). Our cutting-edge technology harnesses the potential of AI to solve complex problems and drive innovation across various industries.
              </p>
            </div>
            <div className="desktopContentSection UIUX" id="UIUX">
              <h1>UI/UX</h1>
              <p>
              At MindFulAI, we specialize in crafting captivating digital experiences through our top-notch UI/UX design services. Our mission is to elevate your brand and delight your users with intuitive and visually stunning interfaces.
              </p>
            </div>
            <div className="desktopContentSection MERN" id="MERN">
              <h1>MERN Stack</h1>
              <p>
              At MindFulAI, we specialize in providing cutting-edge MERN Stack development services to bring your digital ideas to life. Our team of expert developers is dedicated to delivering robust, scalable, and high-performance web applications tailored to your specific requirements.
              </p>
            </div>
            <div className= "desktopContentSection CLOUD"  id="CLOUD">
              <h1 >Cloud Services </h1>
              <p>
              At MindFulAI, we specialize in providing cutting-edge MERN Stack development services to bring your digital ideas to life. Our team of expert developers is dedicated to delivering robust, scalable, and high-performance web applications tailored to your specific requirements.
              </p>
            </div>
            
            <div></div>

          </div>
        </div>

        <div className="right">
          <div className="mobileContent">
            <div className="mobilePhoto red"></div>
            <h1>Machine Learning</h1>
            <p>
            At MindFulAI, we offer advanced machine learning services to help your business thrive. Our solutions are designed to provide valuable insights and automate tasks, making your operations smarter and more efficient.
            </p>

            <div className="mobilePhoto green"></div>
            <h1>Gen AI</h1>
            <p>
            At MindFulAI, we're revolutionizing the way businesses operate with the power of Artificial Intelligence (AI). Our cutting-edge technology harnesses the potential of AI to solve complex problems and drive innovation across various industries.
            </p>

            <div className="mobilePhoto pink"></div>
            <h1>UI/UX</h1>
            <p>
            At MindFulAI, we specialize in crafting captivating digital experiences through our top-notch UI/UX design services. Our mission is to elevate your brand and delight your users with intuitive and visually stunning interfaces.</p>
            <div className="mobilePhoto blue"></div>
            <h1>MERN Stack</h1>
            <p>
            At MindFulAI, we specialize in providing cutting-edge MERN Stack development services to bring your digital ideas to life. Our team of expert developers is dedicated to delivering robust, scalable, and high-performance web applications tailored to your specific requirements.
            </p>

            <div className="mobilePhoto grey"></div>
            <h1>Cloud Services</h1>
            <p>
            At MindFulAI, we specialize in providing cutting-edge MERN Stack development services to bring your digital ideas to life. Our team of expert developers is dedicated to delivering robust, scalable, and high-performance web applications tailored to your specific requirements.
            </p>
            
          </div>

          <div className="desktopPhotos">
            <div className="desktopPhoto red"></div>
            <div className="desktopPhoto green"></div>
            <div className="desktopPhoto pink"></div>
            <div className="desktopPhoto blue"></div>
            <div className="desktopPhoto grey"></div>
          </div>
        </div>
      </div>

  
      <div className="spacer"></div>
      <div className="spacer"></div>
      <div className="spacer"></div>

      {/* </div> */}
    </div>
  );
}



export default Example;