import React from 'react';
import { useNavigate } from 'react-router-dom';
import "../Services/services.css";
import Headimg from "./assets/SAPHEAD.jpeg";
import sa1 from './assets/sa1.png';
import sa2 from './assets/sa2.png';
import sa3 from './assets/sa3.png';
import sa4 from './assets/sa4.png';
import sa5 from './assets/sa5.png';
import sa6 from './assets/sa6.png';
import sa7 from './assets/sa7.png';
import sa8 from './assets/sa8.png';

function Service2() {
  const navigate = useNavigate();

  const handleBookSlotClick = () => {
    navigate('/contact');
  };

  return (
    <div>
      <div className='heading'>
        <div className='headtext'>
          <h1>SAP</h1>
          <p>Optimize your business processes with our expert SAP services, ensuring seamless integration and efficiency.</p>
          <button className='btn' onClick={handleBookSlotClick}>Book your Slot</button>
        </div>
        <div className='blogimg1'>
          <img src={Headimg} alt="SAP" />
        </div>
      </div>

      <div className='process'>
        <h1>Our Working process</h1>
        <div className='steps'>
          <div className='oddstep'>
            <div className='oddstep-text'>
              <p>
                <ul>
                  <li>Investigate the latest SAP solutions and updates.</li>
                  <li>Study business requirements and industry standards.</li>
                </ul>
              </p>
            </div>
            <div className='count1'>
              <h1>01</h1>
              <h6>Research</h6>
            </div>
          </div>

          <div className='evenstep'>
            <div className='count2'>
              <h1>02</h1>
              <h6>Analyse</h6>
            </div>
            <div className='evenstep-text'>
              <p>
                <ul>
                  <li>Assess current systems and identify gaps.</li>
                  <li>Evaluate potential improvements with SAP integration.</li>
                </ul>
              </p>
            </div>
          </div>
           
          <div className='oddstep'>
            <div className='oddstep-text'>
              <p>
                <ul>
                  <li>Develop tailored SAP modules for your business.</li>
                  <li>Create efficient workflows and system architectures.</li>
                </ul>
              </p>
            </div>
            <div className='count3'>
              <h1>03</h1>
              <h6>Design</h6>
            </div>
          </div>

          <div className='evenstep'>
            <div className='count4'>
              <h1>04</h1>
              <h6>Ideate</h6>
            </div>
            <div className='evenstep-text'>
              <p>
                <ul>
                  <li>Brainstorm innovative SAP applications and solutions.</li>
                  <li>Collaborate to enhance system functionalities.</li>
                </ul>
              </p>
            </div>
          </div>

          <div className='oddstep'>
            <div className='oddstep-text'>
              <p>
                <ul>
                  <li>Build initial SAP configurations for testing.</li>
                  <li>Gather feedback to refine and optimize performance.</li>
                </ul>
              </p>
            </div>
            <div className='count5'>
              <h1>05</h1>
              <h6>Prototype</h6>
            </div>
          </div>
        </div>
      </div>

      <div className='toolhead'>
        <h1>Tools we use</h1>
        <div className='tools'>
          <div className='icon'>
            <img src={sa1} alt="Tool 1" /> 
            <img src={sa2} alt="Tool 2" />
            <img src={sa3} alt="Tool 3" />  
            <img src={sa7} alt="Tool 7" /> 
          </div>
          <div className='icon'>
            <img src={sa4} alt="Tool 4" /> 
            <img src={sa5} alt="Tool 5" /> 
            <img src={sa6} alt="Tool 6" /> 
            <img src={sa8} alt="Tool 8" /> 
          </div>
        </div>
      </div>
    </div>
  );
}

export default Service2;
