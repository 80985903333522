import React from 'react'
import '../Section3/Work.css';
import Image from '../Section3/assests/work.jpg'



function Work() {
  return (
      <div className='wculture-wrap'>
      <div className='fwc'>
        <div className='wc'>
          <div className='whead'>
            <p className='head'>Work Culture</p>
            <p className='heads'>Explore our Work Culture</p>
            <div className='wccontent'>
              <p>At <span style={{ color:'#8e4eff'}}>MindFulAI</span>, innovation drives us and collaboration fuels our creativity. We break norms with groundbreaking solutions, set industry standards, and celebrate every voice in our inclusive, diverse team. Join us on our journey to excellence!
              </p>
            </div>
          </div>
      </div>
        
        <div className='animate'>
          {/* <img src={Image} alt='hello' className='pic web'></img> */}
          <img src={Image} alt='hello' className='c-mobile'></img>
        </div>
      </div>
    </div>
  )
}

export default Work
