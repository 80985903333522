import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from React Router
import '../Section4/Trust.css';
import Snsce from '../Section4/Assests/snsce.png';
import Snsarts from '../Section4/Assests/snsarts.png';
import Snsct from '../Section4/Assests/snsct.png';
import Blank from '../Section4/Assests/business.jpg';
import { GoArrowUpRight } from "react-icons/go";

function Trust() {
  return (
    <div className='otp-container'>
      <div className='gb-container'>
        <div className='grow-buisness'>
          <div className='sentence'>
            <p className='words1'>Want to grow your business with us?</p>
            <div className="button-container">
              <div className='contents'>
                <div className="content__item">
                  <Link to="/contact" className="button button--calypso button-size-1">
                    <span>Book a Meet</span>
                    <GoArrowUpRight className="a-icon" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className='blank-img'>
            <img src={Blank} alt="blank" className="b-img" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Trust;
