import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CgWebsite } from 'react-icons/cg';
import { LuBrainCircuit } from 'react-icons/lu';
import { MdDesignServices, MdManageHistory } from 'react-icons/md';
import { GiBlackBook, GiProcessor } from 'react-icons/gi';
import './Navbar.css';
import logo from "../Navbar/assests/Mlogo.png"

const Navbar = () => {
  const [isNavHovered, setNavHovered] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 835);
  const dropdownContentRef = useRef();
  const navbarContainerRef = useRef();
  const [burgerClass, setBurgerClass] = useState("burger-bar unclicked");
  const [menuClass, setMenuClass] = useState("menu hidden");
  const [isMenuClicked, setIsMenuClicked] = useState(false);
  const navigate = useNavigate();

  const updateMenu = () => {
    setBurgerClass(!isMenuClicked ? "burger-bar clicked" : "burger-bar unclicked");
    setMenuClass(!isMenuClicked ? "menu visible" : "menu hidden");
    setIsMenuClicked(!isMenuClicked);
  };

  const handleLinkClick = (path) => {
    navigate(path);
    setBurgerClass("burger-bar unclicked");
    setMenuClass("menu hidden");
    setIsMenuClicked(false);
  };

  const handleMouseEnter = () => {
    setNavHovered(true);
  };

  const handleMouseLeave = () => {
    setNavHovered(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 835);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const updateDropdownHeight = () => {
      const dropdownHeight = isNavHovered ? '130px' : '0px';
      if (dropdownContentRef.current) {
        dropdownContentRef.current.style.transition = 'height 0.5s ease';
        dropdownContentRef.current.style.height = dropdownHeight;
      }
    };

    const updateNavbarHeight = () => {
      const navbarHeight = isNavHovered ? '200px' : '60px';
      if (navbarContainerRef.current) {
        navbarContainerRef.current.style.transition = 'height 0.5s ease';
        navbarContainerRef.current.style.height = navbarHeight;
      }
    };

    updateDropdownHeight();
    updateNavbarHeight();
  }, [isNavHovered]);

  return (
    <>
      {isMobileView ? (
  <div className="navbar-container-mobile">
    <div className="navlogo-content-mobile">
      <Link to="/" className="logo-mobile" onClick={() => handleLinkClick('/')}>
        <img src={logo} alt="Logo" />
      </Link>
      <div className="burger-menu" onClick={updateMenu}>
        <div className={burgerClass}></div>
        <div className={burgerClass}></div>
        <div className={burgerClass}></div>
      </div>
    </div>
    <div className={menuClass}>
      <div className='nav-links-mobile'>
        <Link to="/" className="Nav1text-mobile" onClick={() => handleLinkClick('/')}>
          Home
        </Link>
        <div className="Nav2text-mobile">
          Services
          <div className="dropdown-rows-mobile">
            <Link to="/Service/FSD" onClick={() => handleLinkClick('/Service/FSD')}>
              <CgWebsite className="logo-navbar-services" />
              Full Stack Web Development
            </Link>
            <Link to="/Service/UIUX" onClick={() => handleLinkClick('/Service/UIUX')}>
              <MdDesignServices className="logo-navbar-services" />
              UI/UX Designing
            </Link>
            <Link to="/Service/GenAI" onClick={() => handleLinkClick('/Service/GenAI')}>
              <GiProcessor className="logo-navbar-services" />
              Generative AI
            </Link>
          </div>
          <div className="dropdown-rows-mobile">
            <Link to="/Service/ML" onClick={() => handleLinkClick('/Service/ML')}>
              <LuBrainCircuit className="logo-navbar-services" />
              Machine Learning
            </Link>
            <Link to="/Service/Edu" onClick={() => handleLinkClick('/Service/Edu')}>
              <GiBlackBook className="logo-navbar-services" />
              Education
            </Link>
            <Link to="/Service/SAP" onClick={() => handleLinkClick('/Service/SAP')}>
              <MdManageHistory className="logo-navbar-services" />
              SAP
            </Link>
          </div>
        </div>
        <Link to="/about" className="Nav4text-mobile" onClick={() => handleLinkClick('/about')}>
          About
        </Link>
        <Link to="/contact" className="Nav5text-mobile" onClick={() => handleLinkClick('/contact')}>
          Contact
        </Link>
      </div>
    </div>
  </div>
) : (
        <div
          className="navbar-container-desktop"
          onMouseLeave={handleMouseLeave}
          ref={navbarContainerRef}
        >
          <div className="navlogo-content-desktop">
            <div className="nav-links-desktop">
              <Link to="/" className="Nav1text" onClick={() => handleLinkClick('/')}>
                Home
              </Link>
              <div className="dropdown" onMouseEnter={handleMouseEnter}>
                <Link to="#" className={`Nav2text ${isNavHovered ? 'hovered' : ''}`} aria-expanded={isNavHovered}>

                  Services
                </Link>
              </div>
              <Link to="/" className="logo-desktop" onClick={() => handleLinkClick('/')}>
              <img src={logo} alt='Logo'></img>
              <span className='white-text'>indFul</span>
              <span className='purple-text'>AI</span>
              </Link>
              <Link to="/about" className="Nav4text" onClick={() => handleLinkClick('/about')}>
                About
              </Link>
              <Link to="/contact" className="Nav5text" onClick={() => handleLinkClick('/contact')}>
                Contact
              </Link>
            </div>
          </div>
          <div
          className={`dropdown-content ${isNavHovered ? 'visible' : ''}`}
            ref={dropdownContentRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div className="dropdown-rows">
              <Link to="/Service/FSD" onClick={() => handleLinkClick('/Service/FSD')}>
                <CgWebsite className="logo-navbar-services" />
                Full Stack Web Development
              </Link>
              <Link to="/Service/UIUX" onClick={() => handleLinkClick('/Service/UIUX')}>
                <MdDesignServices className="logo-navbar-services" />
                UI/UX Designing
              </Link>
              <Link to="/Service/GenAI" onClick={() => handleLinkClick('/Service/GenAI')}>
                <GiProcessor className="logo-navbar-services" />
                Generative AI
              </Link>
            </div>
            <div className="dropdown-rows">
              <Link to="/Service/ML" onClick={() => handleLinkClick('/Service/ML')}>
                <LuBrainCircuit className="logo-navbar-services" />
                Machine Learning
              </Link>
              <Link to="/Service/Edu" onClick={() => handleLinkClick('/Service/Edu')}>
                <GiBlackBook className="logo-navbar-services" />
                Education
              </Link>
              <Link to="/Service/SAP" onClick={() => handleLinkClick('/Service/SAP')}>
                <MdManageHistory className="logo-navbar-services" />
                SAP
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;