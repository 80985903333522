import React from 'react';
import ScrollToTop from './ScrollToTop';
// eslint-disable-next-line
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './App.css';
import Footer from './Footer/footer1.js';
import Navbar from './Navbar/Nav.js';
import About from './About/components/App';
import HomePage from './pages/homePage.jsx';
import Contact from '../src/pages/contact.jsx';
import FSD from '../src/Services/fsd';
import GenAI from './Services/genai';
import SAP from './Services/sap';
import ML from './Services/ml';
import UIUX from './Services/uiux';
import Error from './Error/error.js';

const App = () => {
  return (
    <>
      <Router>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/service/fsd" element={<FSD />} />
          <Route path="/service/uiux" element={<UIUX />} />
          <Route path="/service/genai" element={<GenAI />} />
          <Route path="/service/sap" element={<SAP />} />
          <Route path="/service/ml" element={<ML />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<Error />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
};

export default App;
