import { initializeApp } from 'firebase/app';
import { getDatabase, ref, push } from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyDFjMF9zZ_rGV5lM17ogUQsn5ErR6BGs6A",
  authDomain: "mindfulai-contact.firebaseapp.com",
  databaseURL: "https://mindfulai-contact-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "mindfulai-contact",
  storageBucket: "mindfulai-contact.appspot.com",
  messagingSenderId: "109337605992",
  appId: "1:109337605992:web:bbb5d9438b38df0fb1c8dd",
  measurementId: "G-FGNV0J6YD0"
};

const app = initializeApp(firebaseConfig, 'contact');
const database = getDatabase(app);

export { database, ref, push };