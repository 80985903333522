// eslint-disable-next-line
import React, {useRef} from "react";
import "./HomeSec1.css";
// eslint-disable-next-line
import { useTypewriter, Cursor, Typewriter } from 'react-simple-typewriter';
import { Link as ScrollLink } from 'react-scroll';
import { IoMdArrowDown } from "react-icons/io";

const Header= () =>{
  
  const[text] = useTypewriter({
    words: ['Today', 'Tomorrow', 'Future'],
    loop: {},
    typeSpeed:70,
    deleteSpeed:50,
    delaySpeed:1000,
  });

    return(
          <div className="hs1container">
            <div className="hero">
                    <div className="head1-first-line">
                    <h1>Redefining  <span>
                        {text}
                        <Cursor/>
                      </span>
                      </h1>
                  <h1>through Intelligent Realities</h1>
                  </div>
             <p className="para">Welcome to MindFulAI, where innovation meets intelligence. We specialize in leveraging cutting-edge AI solutions to revolutionize businesses and create unparalleled user experiences.</p> 
          <ScrollLink to="home-services" smooth={true} duration={1350}>
              <button className="header-btn" type="button"  >
            <IoMdArrowDown />
            <span>Discover more</span>
          </button>
        </ScrollLink>
        </div>
          </div>
        // <div>
        //  <div className="hs1container">
        //      <h1>
        //        <div className="head1-first-line">
        //        <h1>Redefining</h1><span></span>
        //        <div className="">
        //        <span>
        //            {text}
        //            <Cursor/>
        //          </span>
        //        </div>
        //      </div>Intelligent Realities</h1>
        //  </div>
        //  <div className="para"> <p>Welcome to MindFulAI, where innovation meets intelligence. We specialize in leveraging cutting-edge AI solutions to revolutionize businesses and create unparalleled user experiences.</p>  </div>
        //   <ScrollLink to="home-services" smooth={true} duration={1350}>
        //        <button className="" type="button"  >
        //      <IoMdArrowDown />
        //      <span>Discover more</span>
        //    </button>
        //  </ScrollLink>
        // </div>
    );
}

export default Header;
