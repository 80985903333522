import React from 'react'
import HomeSec1 from '../Home/Section1/HomeSec1'
import Section2 from '../Home/Section2/section2'
import Section3 from '../Home/Section3/Work'
import Section4 from '../Home/Section4/Trust'

const HomePage = () => {
  return (
    <div>
      <HomeSec1 />
      <Section2 /> 
      <Section3 />
      <Section4 />
    </div>
  )
}

export default HomePage
