import React from 'react';
import { useNavigate } from 'react-router-dom';
import "../Services/services.css";
import Headimg from "./assets/UIHEAD.jpeg";
import ui1 from './assets/UI1.png';
import ui2 from './assets/UI2.png';
import ui3 from './assets/UI3.png';
import ui4 from './assets/UI4.png';
import ui5 from './assets/UI5.png';
import ui6 from './assets/UI6.png';
import ui7 from './assets/UI7.png';
import ui8 from './assets/UI8.png';

function Service2() {
  const navigate = useNavigate();

  const handleBookSlotClick = () => {
    navigate('/contact');
  };

  return (
    <div>
      <div className='heading'>
        <div className='headtext'>
          <h1>UI UX Designing</h1>
          <p>Enhance user satisfaction with our expert UI/UX Designing services, creating intuitive and engaging digital experiences.</p>
          <button className='btn' onClick={handleBookSlotClick}>Book your Slot</button>
        </div>
        <div className='blogimg1'>
          <img src={Headimg} alt="UI/UX Designing" />
        </div>
      </div>

      <div className='process'>
        <h1>Our Working process</h1>
        <div className='steps'>
          <div className='oddstep'>
            <div className='oddstep-text'><p>
              <ul>
                <li>Study user behavior and preferences.</li>
                <li>Explore current design trends and best practices.</li>
              </ul>
            </p>
            </div>
            <div className='count1'>
              <h1>01</h1>
              <h6>Research</h6>
            </div>
          </div>

          <div className='evenstep'>
            <div className='count2'>
              <h1>02</h1>
              <h6>Analyse</h6>
            </div>
            <div className='evenstep-text'><p>
              <ul>
                <li>Assess usability issues and pain points.</li>
                <li>Evaluate competitor designs and user feedback.</li>
              </ul>
            </p>
            </div>
          </div>

          <div className='oddstep'>
            <div className='oddstep-text'>
              <p>
                <ul>
                  <li>Create visually appealing and functional interfaces.</li>
                  <li>Develop consistent and responsive design systems.</li>
                </ul>
              </p>
            </div>
            <div className='count3'>
              <h1>03</h1>
              <h6>Design</h6>
            </div>
          </div>

          <div className='evenstep'>
            <div className='count4'>
              <h1>04</h1>
              <h6>Ideate</h6>
            </div>
            <div className='evenstep-text'><p>
              <ul>
                <li>Brainstorm creative design solutions.</li>
                <li>Collaborate to refine concepts for better user engagement.</li>
              </ul>
            </p>
            </div>
          </div>

          <div className='oddstep'>
            <div className='oddstep-text'><p>
              <ul>
                <li>Build interactive mockups for user testing.</li>
                <li>Gather feedback to improve design iterations.</li>
              </ul>
            </p>
            </div>
            <div className='count5'>
              <h1>05</h1>
              <h6>Prototype</h6>
            </div>
          </div>

        </div>
      </div>

      <div className='toolhead'>
        <h1>Tools we use</h1>
        <div className='tools'>
          <div className='icon'>
            <img src={ui1} alt="Tool 1" /> 
            <img src={ui2} alt="Tool 2" />
            <img src={ui3} alt="Tool 3" />  
            <img src={ui7} alt="Tool 7" /> 
          </div>
          <div className='icon'>
            <img src={ui4} alt="Tool 4" /> 
            <img src={ui5} alt="Tool 5" /> 
            <img src={ui6} alt="Tool 6" /> 
            <img src={ui8} alt="Tool 8" /> 
          </div>
        </div>
      </div>
    </div>
  );
}

export default Service2;
