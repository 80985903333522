import React, { useState } from 'react';
import '../ContactCal/ContactCal.css';
import Schedulemeet from '../ContactCal/schedulemeet';
import Maincalendar from '../ContactCal/maincalendar';
import Icon from './assests/video.svg';


function App() {
  const [selectedDate, setSelectedDate] = useState(null);
  const [calendarError, setCalendarError] = useState(false);

  const handleDateSelection = (date) => {
    setSelectedDate(date);
    clearCalendarError(); // Clear calendar error when date is selected
  };

  const clearCalendarError = () => {
    setCalendarError(false);
  };

  return (
    <div className="App">
      <div className='scheduling'>
        <Schedulemeet selectedDate={selectedDate} onDateSelection={handleDateSelection} />
        <div className='main'>
          {/* <Calender /> */}
          <div className='ccontent'>
              <h1 className='first'>Book your Date</h1>
              <label className='second'>
                    <img src={Icon} alt="Icon" /> 
                    <p>Meeting - 30 min</p>
              </label>
      </div>

          <Maincalendar onDateSelection={handleDateSelection} clearError={() => clearCalendarError()} />
          {calendarError && <div className="error-message">Calendar error: Please select a date in the calendar.</div>}
        </div>
      </div>
    </div>
  );
}

export default App;
