import React from 'react';
import { useNavigate } from 'react-router-dom';
import "../Services/services.css";
import Headimg from "./assets/mlHEAD.jpeg";
import ml1 from './assets/ml1.png';
import ml2 from './assets/ml2.png';
import ml3 from './assets/ml3.png';
import ml4 from './assets/ml4.png';
import ml5 from './assets/ml5.png';
import ml6 from './assets/ml6.png';
import ml7 from './assets/ml7.png';
import ml8 from './assets/ml8.png';

function Service2() {
  const navigate = useNavigate();

  const handleBookSlotClick = () => {
    navigate('/contact');
  };

  return (
    <div>
      <div className='heading'>
        <div className='headtext'>
          <h1>Machine Learning</h1>
          <p>Leverage the power of data with our expert Machine Learning services, transforming insights into actionable intelligence.</p>
          <button className='btn' onClick={handleBookSlotClick}>Book your Slot</button>
        </div>
        <div className='blogimg1'>
          <img src={Headimg} alt="Machine Learning" />
        </div>
      </div>

      <div className='process'>
        <h1>Our Working process</h1>
        <div className='steps'>
          <div className='oddstep'>
            <div className='oddstep-text'>
              <p>
                <ul>
                  <li>Investigate latest ML algorithms and techniques.</li>
                  <li>Study data trends and industry-specific needs.</li>
                </ul>
              </p>
            </div>
            <div className='count1'>
              <h1>01</h1>
              <h6>Research</h6>
            </div>
          </div>

          <div className='evenstep'>
            <div className='count2'>
              <h1>02</h1>
              <h6>Analyse</h6>
            </div>
            <div className='evenstep-text'>
              <p>
                <ul>
                  <li>Examine datasets for patterns and correlations.</li>
                  <li>Identify key metrics and performance indicators.</li>
                </ul>
              </p>
            </div>
          </div>
           
          <div className='oddstep'>
            <div className='oddstep-text'>
              <p>
                <ul>
                  <li>Develop data models tailored to your business.</li>
                  <li>Create efficient data processing pipelines.</li>
                </ul>
              </p>
            </div>
            <div className='count3'>
              <h1>03</h1>
              <h6>Design</h6>
            </div>
          </div>

          <div className='evenstep'>
            <div className='count4'>
              <h1>04</h1>
              <h6>Ideate</h6>
            </div>
            <div className='evenstep-text'>
              <p>
                <ul>
                  <li>Brainstorm innovative applications of ML.</li>
                  <li>Collaborate on enhancing model accuracy and relevance.</li>
                </ul>
              </p>
            </div>
          </div>

          <div className='oddstep'>
            <div className='oddstep-text'>
              <p>
                <ul>
                  <li>Build initial ML models for testing.</li>
                  <li>Collect feedback to refine and optimize algorithms.</li>
                </ul>
              </p>
            </div>
            <div className='count5'>
              <h1>05</h1>
              <h6>Prototype</h6>
            </div>
          </div>
        </div>
      </div>

      <div className='toolhead'>
        <h1>Tools we use</h1>
        <div className='tools'>
          <div className='icon'>
            <img src={ml1} alt="Tool 1" /> 
            <img src={ml2} alt="Tool 2" />
            <img src={ml3} alt="Tool 3" />  
            <img src={ml7} alt="Tool 7" /> 
          </div>
          <div className='icon'>
            <img src={ml4} alt="Tool 4" /> 
            <img src={ml5} alt="Tool 5" /> 
            <img src={ml6} alt="Tool 6" /> 
            <img src={ml8} alt="Tool 8" /> 
          </div>
        </div>
      </div>
    </div>
  );
}

export default Service2;
