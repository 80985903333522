import React, { useState } from 'react';
import './schedulemeet.css';
import { database, ref, push } from './firebase';

const Schedulemeet = ({ selectedDate, onDateSelection }) => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    message: '',
  });

  const [formErrors, setFormErrors] = useState({
    fullName: false,
    email: false,
    message: false,
    calendar: false,
  });

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    setFormErrors({ ...formErrors, [event.target.name]: false });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  

    const isValid = validateForm();
  
    if (isValid) {
      const meetingRef = ref(database, 'meetings');
      push(meetingRef, { ...formData, selectedDate });
  

      setFormData({ fullName: '', email: '', message: '' });
      onDateSelection(null);
      

      setFormErrors({ ...formErrors, calendar: false });
      alert('Form submitted successfully. Thank you');
    }
  };
  

  const validateForm = () => {
    const errors = {
      fullName: !formData.fullName.match(/^[a-zA-Z ]+$/),
      email: !formData.email.match(/^\S+@\S+\.\S+$/),
      message: formData.message.length < 10,
      calendar: !selectedDate,
    };

    setFormErrors(errors);


    return !Object.values(errors).some(Boolean);
  };

  return (
    <div className='scheduleform'>
      <h1>Schedule a Meeting</h1>
      <p>Ready to explore the potential of AI for your business? Schedule a meeting with our experts today and embark on a transformative journey towards innovation.</p>
      <input
        type="text"
        placeholder='Full Name'
        name="fullName"
        value={formData.fullName}
        onChange={handleChange}
        className={formErrors.fullName ? 'error' : ''}
      />
      {formErrors.fullName && <div className="error-message">Please enter a valid name</div>}
      
      <input
        type="email"
        placeholder='Email Address'
        name="email"
        value={formData.email}
        onChange={handleChange}
        className={formErrors.email ? 'error' : ''}
      />
      {formErrors.email && <div className="error-message">Please enter a valid email address</div>}
      
      <textarea
        placeholder='Message'
        name="message"
        value={formData.message}
        onChange={handleChange}
        className={formErrors.message ? 'error' : ''}
      />
      {formErrors.message && <div className="error-message">Please enter a message with at least 10 characters</div>}

      <button onClick={handleSubmit}>Submit</button>
      
      {formErrors.calendar && (
        <div style={{ textAlign: 'center', marginBottom: '0px',marginTop: '4px',color:'black',fontSize:'14px'}}>
           <span style={{color:'red',fontSize:'14px'}}> * </span>Please select a date in the calendar
        </div>
      )}
    </div>
  );
};

export default Schedulemeet;
