import React from 'react';
import { useNavigate } from 'react-router-dom';
import "../Services/services.css";
import Headimg from "./assets/GENAIHEAD.jpeg";
import gi1 from './assets/gi1.png';
import gi2 from './assets/gi2.png';
import gi3 from './assets/gi3.png';
import gi4 from './assets/gi4.png';
import gi5 from './assets/gi5.png';
import gi6 from './assets/gi6.png';
import gi7 from './assets/gi7.png';
import gi8 from './assets/gi8.png';

function Service2() {
  const navigate = useNavigate();

  const handleBookSlotClick = () => {
    navigate('/contact');
  };

  return (
    <div>
      <div className='heading'>
        <div className='headtext'>
          <h1>Generative AI</h1>
          <p>Unlock innovative possibilities with our Generative AI services, crafting intelligent solutions that adapt and evolve.</p>
          <button className='btn' onClick={handleBookSlotClick}>Book your Slot</button>
        </div>
        <div className='blogimg1'>
          <img src={Headimg} alt="Generative AI" />
        </div>
      </div>

      <div className='process'>
        <h1>Our Working process</h1>
        <div className='steps'>
          <div className='oddstep'>
            <div className='oddstep-text'>
              <p>
                <ul>
                  <li>Explore advancements in generative AI models.</li>
                  <li>Study industry-specific AI applications and needs.</li>
                </ul>
              </p>
            </div>
            <div className='count1'>
              <h1>01</h1>
              <h6>Research</h6>
            </div>
          </div>

          <div className='evenstep'>
            <div className='count2'>
              <h1>02</h1>
              <h6>Analyse</h6>
            </div>
            <div className='evenstep-text'>
              <p>
                <ul>
                  <li>Examine data sets for training AI models.</li>
                  <li>Identify use cases and potential AI impacts.</li>
                </ul>
              </p>
            </div>
          </div>
           
          <div className='oddstep'>
            <div className='oddstep-text'>
              <p>
                <ul>
                  <li>Develop custom AI algorithms for specific tasks.</li>
                  <li>Create scalable AI infrastructure.</li>
                </ul>
              </p>
            </div>
            <div className='count3'>
              <h1>03</h1>
              <h6>Design</h6>
            </div>
          </div>

          <div className='evenstep'>
            <div className='count4'>
              <h1>04</h1>
              <h6>Ideate</h6>
            </div>
            <div className='evenstep-text'>
              <p>
                <ul>
                  <li>Brainstorm unique applications for generative AI.</li>
                  <li>Collaborate to enhance AI capabilities and creativity.</li>
                </ul>
              </p>
            </div>
          </div>

          <div className='oddstep'>
            <div className='oddstep-text'>
              <p>
                <ul>
                  <li>Build initial AI models for practical testing.</li>
                  <li>Collect feedback to refine and optimize performance.</li>
                </ul>
              </p>
            </div>
            <div className='count5'>
              <h1>05</h1>
              <h6>Prototype</h6>
            </div>
          </div>
        </div>
      </div>

      <div className='toolhead'>
        <h1>Tools we use</h1>
        <div className='tools'>
          <div className='icon'>
            <img src={gi1} alt="Tool 1" /> 
            <img src={gi2} alt="Tool 2" />
            <img src={gi3} alt="Tool 3" />  
            <img src={gi7} alt="Tool 7" /> 
          </div>
          <div className='icon'>
            <img src={gi4} alt="Tool 4" /> 
            <img src={gi5} alt="Tool 5" /> 
            <img src={gi6} alt="Tool 6" /> 
            <img src={gi8} alt="Tool 8" /> 
          </div>
        </div>
      </div>
    </div>
  );
}

export default Service2;
