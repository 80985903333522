import React from 'react';
import { useNavigate } from 'react-router-dom';
import DummyImg from '../assets/Business.png';
import arrow from '../assets/arrow.png';
import '../styles/joinus.css';

const Joinus = () => {
  const navigate = useNavigate();

  const handleBookMeetClick = (event) => {
    console.log("Book a Meet button clicked!");
    event.preventDefault();
    navigate('/contact');
  };

  return (
    <div className='section-meet'>
      <div className='container-meet'>
        <div className='content-meet'>
          <h1 className='cm-text'>Want to grow your business with us?</h1>
          <button className='content-meet-button' onClick={handleBookMeetClick}>
            <span>Book a Meet</span>
            <img src={arrow} alt="Arrow Icon" />
          </button>
        </div>
        <div className='image-meet'>
          <img src={DummyImg} alt="Business Illustration" />
        </div>
      </div>
    </div>
  );
};

export default Joinus;
