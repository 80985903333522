import Img1 from '../assets/Pics/Aadithyan.png';
import Img2 from '../assets/Pics/Abi.png';
import Img3 from '../assets/Pics/Dharanish.png';
import Img4 from '../assets/Pics/Gobhikha.png';
import Img5 from '../assets/Pics/Gopika.png';
import Img6 from '../assets/Pics/Harini.png';
import Img7 from '../assets/Pics/Harisri.png';
import Img8 from '../assets/Pics/Harivarshan.png';
import Img9 from '../assets/Pics/Kavin.png';
import Img10 from '../assets/Pics/Kolaa.png';
import Img11 from '../assets/Pics/Gouse.png';
import Img12 from '../assets/Pics/PriyaK.png';
import Img13 from '../assets/Pics/Ragul.png';
import Img14 from '../assets/Pics/Raja.png';
import Img15 from '../assets/Pics/Revathy.png';
import Img16 from '../assets/Pics/Subhashith.png';
import Img17 from '../assets/Pics/Subhiksha.png';
import Img18 from '../assets/Pics/Suresh.png';
import Img19 from '../assets/Pics/Vigneshwar.png';




export const members = [
    {id:10,name: 'Kolaa Pravin', role:'Technical and Operation Manager', src: Img10, Linkedin: "https://www.linkedin.com/in/kolaa-pravin/",background:"rgb(95, 139, 227)"},
    {id:18,name: 'Suresh S', role:'Technical and Development Manager', src:Img18, Linkedin: "https://www.linkedin.com/in/s-u-r-e-s-h/",background:"rgb(95, 139, 227)"},
    {id:16,name: 'Subhashith V', role:'Technical and Development Manager', src:Img16, Linkedin: "https://www.linkedin.com/in/subhashith-v/",background:"rgb(95, 139, 227)"},
    {id:9,name: 'KavinKumar K', role:'HR Manager', src:Img9, Linkedin: "https://www.linkedin.com/in/kavin-kumar-karthikeyan/",background:"rgb(95, 139, 227)"},
    {id:6,name: 'Harini K', role:'Organic Growth Manager', src:Img6, Linkedin: "https://www.linkedin.com/in/harini-kandasamy/",background:"rgb(95, 139, 227)"},
    {id:19,name: 'Vigneshwar KS', role:'Creative Design Manager', src:Img19, Linkedin: "https://www.linkedin.com/in/vigneshwar-ks/",background:"rgb(95, 139, 227)"},
    {id:7,name: 'Harisri M', role:'Development and Networking Manager', src:Img7, Linkedin: "https://www.linkedin.com/in/harisrimanikandan/",background:"rgb(95, 139, 227)"},
    {id:1,name: 'Aadithyan A', role:'Technical and Development \u00A0 Lead', src: Img1 , Linkedin: "https://www.linkedin.com/in/aadithyan-a-/",background:"rgb(95, 139, 227)"},
    {id:8,name: 'Harivarshan UG', role:'Creative Designs and Networking Lead', src:Img8, Linkedin: "https://www.linkedin.com/in/harivarshan-ganapathi/",background:"rgb(95, 139, 227)"},
    {id:12,name: 'Priyadharshini K', role:'Organic Growth Lead', src:Img12, Linkedin: "https://www.linkedin.com/in/priyadharshini-kulothungan/",background:"rgb(95, 139, 227)"},
    {id:15,name: 'Revathy R', role:'Organic Growth Lead', src:Img15, Linkedin: "https://www.linkedin.com/in/revathy-r/",background:"rgb(95, 139, 227)"},
    {id:17,name: 'Subhiksha S', role:'Organic Growth Lead', src:Img17, Linkedin: "https://www.linkedin.com/in/subhiksha-sukumar/",background:"rgb(95, 139, 227)"},
    {id:4,name: 'Gobhikha K', role:'Training Lead', src:Img4, Linkedin: "https://www.linkedin.com/in/gobikha-k-201205113/",background:"rgb(95, 139, 227)"},
    {id:2,name: 'Abinaya N', role:'Technical Supervisor', src:Img2, Linkedin: "https://www.linkedin.com/in/abinaya-narayanan/",background:"rgb(95, 139, 227)"},
    {id:14,name: 'Rajagopal R', role:'Technical Supervisor', src:Img14, Linkedin: "https://www.linkedin.com/in/rajagopalrj/",background:"rgb(95, 139, 227)"},
    {id:11,name: 'Mohammed Gouse B', role:'Technical Supervisor', src:Img11, Linkedin: "https://www.linkedin.com/in/1709-mdgouse/",background:"rgb(95, 139, 227)"},
    {id:3,name: 'Dharanish N', role:'Process Analyst', src:Img3, Linkedin: "https://www.linkedin.com/in/dharanish-n-/",background:"rgb(95, 139, 227)"},
    {id:5,name: 'Gopika M', role:'Process Analyst', src:Img5, Linkedin: "https://www.linkedin.com/in/gopika-chandran-/",background:"rgb(95, 139, 227)"},
    {id:13,name: 'Ragul R', role:'Process Analyst', src:Img13, Linkedin: "https://www.linkedin.com/in/iamragulr/",background:"rgb(95, 139, 227)"},
];
