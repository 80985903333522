import React, { useState, useRef } from 'react';
import './ContactMap.css';

const Contact = () => {
  const [user, setUser] = useState({
    Name: '',
    Email: '',
    Number: '',
    Message: ''
  });

  const [errors, setErrors] = useState({
    Name: '',
    Email: '',
    Number: '',
    Message: ''
  });

  const formRef = useRef(null);

  const scrollToForm = () => {
    const offset = 550; // Adjust this value to set the desired offset
    const elementPosition = formRef.current.getBoundingClientRect().top + window.pageYOffset;
    const offsetPosition = elementPosition + offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  };

  const validate = () => {
    const newErrors = {};

    if (!user.Name) newErrors.Name = 'Name is required';
    if (!user.Email) {
      newErrors.Email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(user.Email)) {
      newErrors.Email = 'Email is invalid';
    }
    if (!user.Number) {
      newErrors.Number = 'Phone number is required';
    } else if (!/^\d{10}$/.test(user.Number)) {
      newErrors.Number = 'Phone number is invalid';
    }
    if (!user.Message) newErrors.Message = 'Message is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const data = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const getdata = async (e) => {
    e.preventDefault();

    if (!validate()) {
      return;
    }

    const { Name, Email, Number, Message } = user;

    try {
      // eslint-disable-next-line
      const response = await fetch('https://script.google.com/macros/s/AKfycbzhoGOh7rVXA0fvTfu7E7NmbjXaOzkO-rzbjENZQjIXZJHc3zkcnF-H6vGQ8nv4qy8/exec', {
        mode: 'no-cors',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          Name,
          Email,
          Number,
          Message
        })
      });

      // Assuming the form submission was successful since we are using no-cors mode
      setUser({
        Name: '',
        Email: '',
        Number: '',
        Message: ''
      });

      alert('Form submitted successfully. Thank you!. Please fill out the below form if you want to have a meeting scheduled with us');

      // Note: The following line won't work with no-cors mode
      // const responseData = await response.json();
      // localStorage.setItem('contactResponse', JSON.stringify(responseData));
    } catch (error) {
      console.error('Fetch operation Error:', error);
    }
  };

  return (
    <div className='contact-us'>
      <div ref={formRef} className='contact-us-form'>
        <h1>Contact Us!</h1>
        <h5>Let us know how we can help you!</h5>
        <form method='post' onSubmit={getdata}>
          <input
            type="text"
            name='Name'
            placeholder='Name'
            value={user.Name}
            autoComplete='off'
            required
            onChange={data}
          />
          {errors.Name && <p className='errory'>{errors.Name}</p>}
          <input
            type="email"
            name='Email'
            placeholder='Email ID'
            value={user.Email}
            autoComplete='off'
            required
            onChange={data}
          />
          {errors.Email && <p className='errory'>{errors.Email}</p>}
          <input
            type="text"
            name='Number'
            placeholder='Phone'
            value={user.Number}
            autoComplete='off'
            required
            onChange={data}
          />
          {errors.Number && <p className='errory'>{errors.Number}</p>}
          <p>Message</p>
          <textarea
            name='Message'
            placeholder='I have a query about...'
            value={user.Message}
            autoComplete='off'
            required
            onChange={data}
          ></textarea>
          {errors.Message && <p className='errory'>{errors.Message}</p>}
          <button type='submit'>Submit</button>
        </form>
      </div>
      
      <div className='map-container'>
        <p className='map-text'>Our Location</p>
        <iframe
          title="Google Map"
          className='map'
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3861.843839102017!2d77.03096141480354!3d11.10505849057573!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba85825d972bcb1%3A0x218b7d8ff28bc2bf!2sYour%20Location%20Name!5e0!3m2!1sen!2sus!4v1620035571032!5m2!1sen!2sus"
          width="400"
          height="500"
          allowFullScreen=""
          loading="lazy"
        ></iframe>
        <div onClick={scrollToForm} className="submit-button-location">
            <button className="location-button">Book a Slot</button>
        </div>
      </div>
    </div>
  );
}

export default Contact;
